/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, jsx, Heading, Text } from "theme-ui";
import MarkdownRenderer from "./MarkdownRenderer";

const CardBody = styled.div`
  margin-top: 30px;
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
`;

const CardImage = styled.img`
  border-radius: 50%;
  display: block;
  height: 100px;
  margin-right: 28px;
  object-fit: cover;
  width: 100px;
`;

const TeamCard = ({
  cardName,
  cardText,
  cardTitle,
  profileImage,
  profileImageAlt,
  headingSmall,
  headingLarge,
  copySmall,
  copyLarge,
  titleSmall,
  titleLarge,
}) => {
  return (
    <Box as="article">
      <CardHeader>
        <CardImage src={profileImage} alt={profileImageAlt} />
        <Box>
          <Heading
            as="h1"
            sx={{
              fontSize: [headingSmall, null, null, headingLarge],
              fontWeight: "bold",
              lineHeight: "25px",
            }}
          >
            {cardName}
          </Heading>

          <Text
            as="p"
            mt={1}
            sx={{
              opacity: "0.8",
              fontSize: [titleSmall, null, null, titleLarge],
              whiteSpace: ["wrap", null, null, "nowrap"],
            }}
          >
            {cardTitle}
          </Text>
        </Box>
      </CardHeader>

      <CardBody>
        <MarkdownRenderer small={copySmall} large={copyLarge}>
          {cardText}
        </MarkdownRenderer>
      </CardBody>
    </Box>
  );
};

export default TeamCard;
