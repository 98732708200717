/** @jsx jsx */
import React from "react"; //eslint-disable-line no-unused-vars
import { Box, Container, Flex, Grid, Heading, jsx } from "theme-ui";
import { graphql } from "gatsby";

import Footer from "../components/Footer";
import Header from "../components/Header";
import TeamCard from "../components/TeamCard";

import PageData from "../../content/pages/about.yml";
import MarkdownRenderer from "../components/MarkdownRenderer";
import SEO from "../components/SEO";

export const query = graphql`
  query {
    mobileOurStoryDots: file(
      relativePath: { eq: "mobile-our-story-dots.svg" }
    ) {
      id
      publicURL
      extension
    }
    heroBackgroundDots: file(relativePath: { eq: "hero-background-dots.svg" }) {
      id
      publicURL
      extension
    }
    heroDots: file(relativePath: { eq: "hero-dots-2.svg" }) {
      id
      publicURL
      extension
    }
    ourStoryDots: file(relativePath: { eq: "our-story-dots.svg" }) {
      id
      publicURL
      extension
    }
    dosisStoryPic: file(relativePath: { eq: "dosis-story-pic.png" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    shivratChhabra: file(relativePath: { eq: "shivrat-chhabra.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    divyaChhabra: file(relativePath: { eq: "divya-chhabra.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    drGeorgeAronoff: file(relativePath: { eq: "dr-george-aronoff.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    drAdamGaweda: file(relativePath: { eq: "dr-adam-gaweda.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    atomCrimi: file(relativePath: { eq: "atom-crimi.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    oliviaYoon: file(relativePath: { eq: "olivia-yoon.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    danielleBudish: file(relativePath: { eq: "danielle-budish.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    shreyaGhai: file(relativePath: { eq: "shreya-ghai.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    estherMuhs: file(relativePath: { eq: "esther-muhs-aprn.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    drMichealBrier: file(relativePath: { eq: "dr-michael-brier.jpg" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
  }
`;

const AboutUs = ({ data }) => {
  return (
    <>
      <SEO title="About Us" />

      <Header
        heroImage={data.heroDots.publicURL}
        heroTitle={PageData.title}
        heroVariant="medium"
      />

      <Box as="section" pt={[14, null, null, 16]}>
        <Container>
          <Flex sx={{ justifyContent: "center" }}>
            <Box sx={{ flex: "0 0 100%", textAlign: "center" }}>
              <Heading as="h1" mb={5} sx={{ textAlign: "center" }}>
                {PageData.block_1_title}
              </Heading>
              <MarkdownRenderer
                small={"20px"}
                large={"20px"}
                sx={{
                  textAlign: module?.block_1_alignment
                    ? module?.block_1_alignment
                    : "center",
                }}
              >
                {PageData.block_1_intro}
              </MarkdownRenderer>
            </Box>
          </Flex>
        </Container>
      </Box>

      {/* THIS SECTION IS HIDDEN WAITING ON FEEDBACK TO REMOVE OR NOT */}
      {/* <Box as="section" py={[14, null, null, 16]} sx={{ display: "none" }}>
        <Container>
          <Flex sx={{ justifyContent: "center" }}>
            <Box sx={{ flex: "0 0 100%", textAlign: "center" }}>
              <Heading as="h1" mb={5} sx={{ textAlign: "center" }}>
                Who We Are
              </Heading>
              <Text as="p">
                Our passionate, experienced team is dedicated to helping clinics
                change their patients’ lives with cutting-edge AI technology.
              </Text>
            </Box>
          </Flex>
        </Container>
      </Box> */}
      {/*  */}

      {/* THIS SECTION IS HIDDEN WAITING ON FEEDBACK TO REMOVE OR NOT */}
      {/* <Box
        as="section"
        pt={[16, null, 18]}
        pb={[null, null, 18]}
        backgroundColor="secondary"
        color="white"
        sx={{
          display: "none",
          position: "relative",
          backgroundImage: [
            "null",
            null,
            "url(" + data.ourStoryDots.publicURL + ")",
          ],
          backgroundRepeat: "no-repeat",
          backgroundPosition: ["center right"],
        }}
        >
        <Container>
          <Flex
            sx={{
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: ["center", null, "flex-start"],
            }}
          >
            <Box sx={{ flex: ["0 0 100%", null, "0 0 50%"] }}>
              <Heading as="h1" mb={5} sx={{ textAlign: "center" }}>
                The Dosis Story
              </Heading>
              <Text as="p" sx={{ opacity: "0.8" }}>
                Dosis was founded with the mission to transform chronic disease
                management by leveraging advancements in artificial intelligence
                and control algorithms. Our technology is backed by years of
                peer-reviewed research, led by the Brier and Gaweda lab at the
                University of Louisville. With a deep academic history, we use
                science, data, patient safety and clinical evidence to inform
                all of our decisions.
              </Text>
            </Box>
          </Flex>
        </Container>

        <Box
          sx={{
            display: [null, null, "none"],
            flex: ["0 0 100%", null, "0 0 50%"],
          }}
        >
          <Image
            pt={8}
            sx={{
              display: "block",
              maxWidth: "100%",
              width: "100%",
            }}
            src={data.mobileOurStoryDots.publicURL}
            alt="Shivrat Chhabra and Divya Chhabra"
          />
        </Box>
      </Box> */}
      {/*  */}

      <Box as="section" py={[14, null, null, 16]}>
        <Container>
          <Heading
            as="h1"
            mb={[9, null, null, 12]}
            sx={{ textAlign: "center", fontSize: "40px" }}
          >
            {PageData.team_title}
          </Heading>

          <Grid
            sx={{
              gridTemplateColumns: [
                "repeat(1,  100%)",
                "repeat(1,  80%)",
                null,
                "repeat(3,  31%)",
              ],
              rowGap: [12, null, null, 9],
              justifyContent: ["center", null, null, "space-between"],
            }}
          >
            {PageData.team_member.map((member, index) => (
              <TeamCard
                cardName={member.name}
                cardTitle={member.title}
                profileImage={member.image}
                cardText={member.bio}
                copySmall={PageData.mobile_font_sizes.copy}
                copyLarge={PageData.desktop_font_sizes.copy}
                headingSmall={PageData.mobile_font_sizes.sub_title}
                headingLarge={PageData.desktop_font_sizes.sub_title}
                titleSmall={PageData.mobile_font_sizes.light_copy}
                titleLarge={PageData.mobile_font_sizes.light_copy}
                key={index}
              />
            ))}
          </Grid>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default AboutUs;
